$header-space: 20px;
$normal-space: 5px;

.ed-v3 {
  font-family: 'Arial', sans-serif;

  iframe {
    max-width: 560px;
    height: 315px;
  }

  a {
    text-decoration: underline;
    color: #337ab7;

    &:hover {
      color:#286090;
    }
  }

  h1 {
    font-size: 32px;
    margin-bottom: $header-space;
  }

  h2 {
    font-size: 26px;
    margin-bottom: $header-space;
  }

  h3 {
    font-size: 20px;
    margin-bottom: $header-space;
  }

  p {
    padding-top: $normal-space;
    padding-bottom: $normal-space;
  }

  ul {
    // this was originally margin, but it did not do anything
    // if the spacing should be added here, we may want to modify education QA checks
    padding-bottom: $normal-space;
    padding-top: $normal-space;
    list-style: disc inside;
  }
  ol {
    padding-bottom: $normal-space;
    padding-top: $normal-space;
    list-style: decimal;
  }

  li {
    margin-left: 20px;
    list-style-position: outside;
  }

  .clear {
    clear: both;
  }

  .text-box {
    border: 1px solid #333;
    background-color: #ccc;

    // does not do anything
    // margin-top: 10px;
    // margin-bottom: 10px;

    .header {
      padding: 10px;
      position: relative;
      min-height: 50px;
      margin-right: 30px;
      margin-left: 30px;

      .icon {
        float: right;
        text-align: right;
        position: absolute;
        top: 50%;
        right: 0px;

        img {
          margin-top: -25px;
          width: 60px;
          height: 50px;
        }
      }

      .header-text {
        float: left;
        font-weight: bold;
        vertical-align: middle;
        padding-top: 20px;
        margin-right: 70px;
      }
    }

    .body {
      clear: both;
      padding: 40px;
      border-top: 1px solid #333;
      background-color: #fff;

      &.no-header {
        border-top: none;
      }

      &.centered {
        text-align: center;
      }
    }
  }

  // to refactor:
  img {
    max-width: 800px;
  }

  img.full-width {
    width: 100%;
  }

  img.half-width-1 {
    width: 49%;
  }

  img.half-width-2 {
    width: 49%;
    margin-left: 2%;
  }

  img.half-width-collapse-1 {
    width: 49%;
  }

  img.half-width-collapse-2 {
    width: 49%;
    margin-left: 2%;
  }

  .image-with-description {
    width: 100%;
    float: left;
  }

  .image-with-description img {
    width: 100%;
  }

  .image-with-description.half-width-image-with-description-1 {
    width: 49%;
  }

  .image-with-description.half-width-image-with-description-2 {
    width: 49%;
    margin-left: 2%;
  }

  .image-with-description-collapse {
    width: 100%;
    float: left;
  }

  .image-with-description-collapse img {
    width: 100%;
  }

  .image-with-description-collapse.half-width-image-with-description-collapse-1 {
    width: 49%;
  }

  .image-with-description-collapse.half-width-image-with-description-collapse-2 {
    width: 49%;
    margin-left: 2%;
  }

  .image-description {
    text-align: center;
    padding: 10px;
  }

  @media (max-width : 720px) {
    img.half-width-collapse-1 {
      width: 100%;
    }

    img.half-width-collapse-2 {
      width: 100%;
      margin-left: 0%;
      margin-top: 2%;
    }

    .image-with-description-collapse {
      float: none;
    }

    .image-with-description-collapse.half-width-image-with-description-collapse-1 {
      width: 100%;
    }

    .image-with-description-collapse.half-width-image-with-description-collapse-2 {
      width: 100%;
      margin-left: 0%;
      margin-top: 2%;
    }
  }
}