body, div {
  /*  Firefox*/
  scrollbar-width: thin;

  /*  https://gist.github.com/spemer/a0e218bbb45433bd611e68446523a00b
  Customize website's scrollbar like Mac OS in Chrome
  */
  &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
      background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
      display:none;
  }

  &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0);
      border-radius:16px;
      border:5px solid #fff;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
  }

  &::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
      border:4px solid #f4f4f4
  }
}
