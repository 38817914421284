
.SingleDatePickerInput {
  border: none;
  display: flex;
  font-family: inherit;
}

.DateInput_input {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  width: 100%;
}

.DateInput_input__focused {
  border: none;
}

.CalendarDay:hover {
  background: #B2E8F4;
}

.CalendarDay__selected, .CalendarDay__selected:hover {
  background: #044F89;
  border: 1px double #000;
}
